<template>
  <div v-if="!isLoading || isLoadedBefore">
    <b-form v-if="totalRows !== 0" inline>
      <b-pagination
        :disabled="isLoading && isLoadedBefore"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        :aria-controls="ariaControls"
        :hidden="paginationHidden"
        @change="emitCurrentSkipTop"
        class="mb-0"
      ></b-pagination>
      <b-dropdown
        :disabled="isLoading && isLoadedBefore"
        :hidden="dropdownHidden"
        variant="outline-secondary"
        :text="perPage.toString()"
        size="sm"
        class="ml-2 mr-2 p-1"
      >
        <b-dropdown-item
          v-for="(item, i) in itemPerPageArray"
          :key="'tabledropdown-' + i"
          :active="perPage === item"
          @click="
            perPage = item;
            emitCurrentSkipTop(1);
          "
          >{{ item }}</b-dropdown-item
        >
      </b-dropdown>
      <span class="text-muted">{{
        $t('COMMON.TABLE.ITEMCOUNTDISPLAY', {
          perPage: currentRows,
          length: totalRows,
        })
      }}</span>
    </b-form>
  </div>
  <b-skeleton v-else height="35px" width="25%"></b-skeleton>
</template>
<script>
export default {
  name: 'SKRPagination',
  components: {},
  props: {
    isLoading: { type: Boolean, default: false },
    totalRows: { type: Number, required: true },
    overwriteCurrentPage: { type: Number, required: false },
    currentRows: { type: Number, required: true },
    paginationHidden: { type: Boolean, default: false },
    dropdownHidden: { type: Boolean, required: false },
    perPageOverwrite: { type: Number, required: false },
    ariaControls: { type: String, required: false, default: '' },
  },
  data() {
    return {
      isLoadedBefore: false,
      hidden: false,
      currentPage: 1,
      perPage: 25,
      itemPerPageArray: [10, 25, 50, 100],
    };
  },
  created() {
    if (this.perPageOverwrite) {
      this.perPage = this.perPageOverwrite;
    }
  },
  mounted() {
    this.isLoadedBefore = true;
  },
  watch: {
    totalRows: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.currentPage = 1;
        }
      },
    },
    overwriteCurrentPage: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.currentPage = value;
        }
      },
    },
  },
  computed: {
    skip() {
      return this.perPage * (this.currentPage - 1);
    },
    top() {
      return this.perPage;
    },
  },
  methods: {
    emitCurrentSkipTop(page) {
      this.currentPage = page;
      this.$emit('pageChange', Object.assign({ page, skip: this.skip, top: this.top }));
    },
  },
};
</script>
